import React from 'react'

import { Link } from 'gatsby'
import Img from 'gatsby-image'

import styled from 'styled-components'

import Layout from '../../components/layout'

import { MainSectionWrapper } from '../../components/common/styles'

const MainSectionWrapperOverride = styled(MainSectionWrapper)`
    padding: 0;
`

const CeramicsSectionPhotoWrapper = styled.div`
    width: 47%;

    display: grid;
    place-items: center;

    & img {
        margin-bottom: 0;
    }

    & a {
        width: 100%;
    }
`

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  transition: .5s ease;
  background-color: ${props => `rgba(${props.overlayColor.r}, ${props.overlayColor.g}, ${props.overlayColor.b}, 0)`};

  display: flex;
  justify-content: center;
  align-items: center;

  color: transparent;

  z-index: 2;

  &:hover {
      background-color: ${props => `rgba(${props.overlayColor.r}, ${props.overlayColor.g}, ${props.overlayColor.b}, ${props.transparency})`};

      color: ${props => props.textColor};
  }
`

const CeramicsPage = ({ data }) => (
    <Layout>
        <MainSectionWrapperOverride>
            <div style={{ display: 'flex', height: '100%', justifyContent: 'space-between' }}>
                <CeramicsSectionPhotoWrapper>
                    <Link to="/ceramics/earrings">
                        <div style={{ position: 'relative', cursor: 'pointer' }}>
                            <ImageOverlay textColor='white' overlayColor={{ r: 0, g: 255, b: 255 }} transparency={0.40}>
                                <div style={{ fontSize: '48px', fontWeight: 700 }}>
                                    Earrings
                                </div>
                            </ImageOverlay>
                            <Img fluid={data.earrings.childImageSharp.fluid} />
                        </div>
                    </Link>
                </CeramicsSectionPhotoWrapper>
                <CeramicsSectionPhotoWrapper>
                    <Link to="/ceramics/cups">
                        <div style={{ position: 'relative', cursor: 'pointer' }}>
                            <ImageOverlay textColor='white' overlayColor={{ r: 0, g: 255, b: 255 }} transparency={0.30}>
                                <div style={{ fontSize: '48px', fontWeight: 700 }}>
                                    Cups
                            </div>
                            </ImageOverlay>
                            <Img fluid={data.cups.childImageSharp.fluid} />
                        </div>
                    </Link>
                </CeramicsSectionPhotoWrapper>
            </div>
        </MainSectionWrapperOverride>
    </Layout>
)

export default CeramicsPage

export const squareImage = graphql`
fragment image on File {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
}
`;

export const query = graphql`
  query {
    earrings: file(relativePath: { eq: "images/ceramics/earrings/1image.jpg" }) {
        ...image
    }
    cups: file(relativePath: { eq: "images/ceramics/cups/1image.jpg" }) {
        ...image
    }
  }
`
