import styled from 'styled-components'

export const PageHeader = styled.h1`
  text-transform: uppercase;
  text-align: center;
  font-weight: 900;
  color: #83ccb8;
`

export const MainSectionWrapper = styled.div`
  margin: 0 auto;
  max-width: 960;
  padding: 0px 1.0875rem 1.45rem;
  padding-top: 0;
  grid-row: 3;
  grid-column: 3;
  place-self: center;

  width: 100%;
  height: 100%;

  overflow: scroll;
`